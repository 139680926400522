import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from './store'
import News from "./components/News"
// import Login from "./components/Auth/Login"
import Logout from "./components/Auth/Logout"
import ScoreStudy from "./components/score/Study"
import Menu from "./components/dashboard/menu"
// import Email from "./components/email/Email"
// import Profile from "./components/profile/Profile"
import store from './store'

//ทดสอบสร้างตัวแปรว่าลอคอินหรือยัง
// let state = false
//สร้างฟังก์ชันเช็ค route ก่อนเรียกใช้งาน (Route Auth Guard)
// function authGuard(to, from, next){
  
//   let isAuthenticated = false

//   if(localStorage.getItem('smk_access_token')){
//     isAuthenticated = true
//   }else{
//     isAuthenticated = false
//   }

//   if(isAuthenticated){
//     next() //อนุญาติให้เข้าสู่ route และโหลด component ที่ต้องการได้
//   }else{
//     next({name: 'login'})
//   }

// }

function authGuard(to, from, next){
  let isAuthenticated = false
  if(store.getters['authentication/logedIn']){
    isAuthenticated = true
  }else{
    isAuthenticated = false
  }
  if(isAuthenticated){
    next() //อนุญาติให้เข้าสู่ route และโหลด component ที่ต้องการได้
    // router.push({
    //   name: 'Dashboard'
    // })
  }else{
    next({name: 'login'})
  }
}

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      // name: 'menu',
      component: Menu,
      beforeEnter: authGuard,
      meta: {
        title: 'Beranda'
      }
    },
    {
      path: '/menu',
      name: 'Menu',
      component: Menu,
      beforeEnter: authGuard,
      meta: {
        title: 'Beranda'
      }
    },
    {
      path: '/news',
      name: 'news',
      component: News,
      beforeEnter: authGuard,
      meta: {
        title: 'Isytihar'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: require('./components/Auth/Login.vue').default,
      meta: {
        title: 'LOGIN',
        description: 'login',
        requiresVisitor: true
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/score-study',
      name: 'score-study',
      component: ScoreStudy,
      meta: {
        title: 'Hasil kuliah'
      },
      // meta: {requiresAuth: true}
      beforeEnter: authGuard
    },
    {
      path: '/profile',
      name: 'profile',
      component: require('./components/profile/Profile.vue').default,
      meta: {
        title: 'Profil',
        // domains: 'student.kisda.online'
      },
      // meta: {requiresAuth: true}
      beforeEnter: authGuard
    },
    {
      path: '/profileEdit',
      name: 'profileEdit',
      component: require('./components/profile/BiodataForm.vue').default,
      meta: {
        title: 'Ubah profil'
      },
      beforeEnter: authGuard
    },
    // {
    //   path: '/email',
    //   name: 'email',
    //   component: Email,
    //   // meta: {requiresAuth: true} 
    //   beforeEnter: authGuard
    // },
    {
      path: '/leaning',
      name: 'learning',
      component: require('./components/learning/Learning.vue').default,
      meta: {
        title: 'Perkuliahan'
      },
      // meta: {requiresAuth: true}
      beforeEnter: authGuard
    },
    {
      path: '/lineNotify',
      name: 'lineNotify',
      component: require('./components/lineNotify/Index.vue').default,
      // meta: {requiresAuth: true}
      meta: {
        title: 'Line notify'
      },
      beforeEnter: authGuard
    },
    {
      path: '/studyResult',
      name: 'studyResult',
      component: require('./components/studyResult/Index.vue').default,
      // meta:{requireAuth: true}
      meta: {
        title: 'Hasil kuliah'
      },
      beforeEnter: authGuard
    },
    {
      path: '/payment',
      name: 'payment',
      component: require('./components/payment/Index.vue').default,
      // meta: {requiresAuth: true}
      meta: {
        title: 'Bayaran'
      },
      beforeEnter: authGuard
    },
    {
      path: '/attendance',
      name: 'attendance',
      component: require('./components/attendance/Index.vue').default,
      meta: {
        title: 'Kehadiran'
      },
      beforeEnter: authGuard
    },
    {
      path: '/attendanceDetail',
      name: 'attendanceDetail',
      component: require('./components/attendance/Detail.vue').default,
      meta:{
        title: 'Laporan kehadiran'
      },
      beforeEnter: authGuard
    },
    {
      path: '/tamhidiResult',
      name: 'TamhidiResult',
      component: require('./components/tamhidi/Index.vue').default,
      meta: {
        title: 'Hasil tamhidi / mustawa'
      },
      beforeEnter: authGuard
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(!store.getters['authentication/logedIn']){
      router.push({
        name: 'login' 
      })
    } else {
      next()
    }
  }else if(to.matched.some(record => record.meta.requiresVisitor)) {
    if(store.getters['authentication/logedIn']){
      router.push({
        name: 'Menu' 
      })
    }else{
      next()
    }
  }else{
    next()
  }
})

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//       if(!localStorage.getItem('smk_access_token')){
//         next({
//           name: 'login',
//         })
//       } else {
//         next()
//       }
//     }else if(to.matched.some(record => record.meta.requiresVisitor)) {
//       if(localStorage.getItem('smk_access_token')){
//         next({
//           name: 'menu',
//         })
//       }else{
//           next()
//       }
//     }else{
//       next()
//     }
//   })

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if(!localStorage.getItem('smk_access_token')){
//       next({
//         name: 'Login',
//       })
//     } else {
//       next()
//     }
//   }else if(to.matched.some(record => record.meta.requiresVisitor)) {
//     if(localStorage.getItem('smk_access_token')){
//       next({
//         name: 'menu',
//       })
//     }else{
//         next()
//     }
//   }else{
//     next()
//   }
// })

export default router