<template>
    <div class="container-fluid">
        <div class="row mt-3 justify-content-center">
            <div class="col-1 col-12 col-md-12 col-lg-12 col-xl-12">
                <h4 class="text-center">HASIL PERKULIAHAN</h4>
                <div class="accordion" id="accordionExample">
                    <div class="card" v-for="grade in grades" :key="grade.sr_id">
                        <div class="card-header bg-info" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left collapsed text-white" type="button" data-toggle="collapse" :data-target="'#Collapse'+grade.sr_id" aria-expanded="true" :aria-controls="grade.sr_id">
                                 {{grade.semester}} / {{grade.year}}
                            </button>
                        </h2>
                        </div>

                        <div :id="'Collapse'+grade.sr_id" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                <ul>
                                    <li v-for="(score, index) in grade.subjects" :key="index">
                                        <span><b>{{index+1}} . {{score.s_code}} {{score.s_rumiName}}</b></span>
                                        <ul>
                                            <!-- <li>Madah : {{score.s_code}} {{score.s_rumiName}}</li>  -->
                                            <li><b>Pensyarah :</b> {{score.t_fnameRumi}} {{score.t_lnameRumi}}</li> 
                                            <li :class="textFilter(score.ss_score)"><b>Markah :</b> {{score.ss_score}}</li> 
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion" id="accordionExample" v-if="transfers.length">
                    <div class="card">
                        <div class="card-header bg-warning" id="headingOne">
                        <h2 class="mb-0">
                            <button class="btn btn-block text-left collapsed text-white" type="button" data-toggle="collapse" data-target="#CollapseKutipan" aria-expanded="true" aria-controls="333">
                                 madah kutipan
                            </button>
                        </h2>
                        </div>

                        <div id="CollapseKutipan" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div class="card-body">
                                <ul>
                                    <li v-for="(item, index) in transfers" :key="index">
                                        <span><b>({{index+1}}). {{item.semester}}/{{item.year}} {{item.s_code}} {{item.s_rumiName}}</b></span>
                                        <ul>
                                            <li><b>Pensyarah : </b> {{item.t_fnameRumi}} {{item.t_lnameRumi}}</li>
                                            <li :class="textFilter(item.ss_score)"><b>Markah : </b> {{item.ss_score}}</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <br><br><br>
            </div>
        </div>
    </div>
</template>

<script>

    // import axios from 'axios'
    // import config from '../../config'
    import http from '@/services/BackendService'

    export default {
        mounted(){
            this.retreiveStudyResult()
            this.retreiveTransferSubject()
        },
        data(){
            return{
                grades: [],
                transfers: [],
            }
        },
        methods:{
            retreiveTransferSubject(){
                http.get(`academicResult/transferSubject`)
                    .then(response => {
                        console.log(response.data)
                        this.transfers = response.data.subjects
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            textFilter(score){
                if(score == '' || score == null){
                    return 'text-warning'
                }else if(score < 50){
                    return 'text-danger'
                }else{
                    return 'text-success'
                }
            },
            retreiveStudyResult(){
                this.$Progress.start()
                // axios.get(`${config.apiURL}/academicResult/grades`, {
                http.get(`academicResult/grades`).then(response => {
                    this.grades = response.data.result
                    this.$Progress.finish()
                }).catch(() => {
                    this.$Progress.fail()
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>