<template>
    <div>

    </div>
</template>

<script>
    export default {
        created(){
            // console.log('aaaa')
            this.$Progress.start()
            this.$store.dispatch('authentication/signOut')
                .then(() => {
                    this.$router.push({name: 'login'})
                    // console.log('loged out')
                    this.$Progress.finish()
                })
                .catch(error => {
                    if(error.response.status == 401){
                        // localStorage.removeItem('smk_access_token')
                        // localStorage.removeItem('smk_device_name')
                        localStorage.removeItem('authStatus')
                        this.$router.push({name: 'login'})
                    }
                    this.$Progress.fail()
                })
        }
    }
</script>

<style lang="scss" scoped>

</style>