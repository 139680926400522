<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-center mt-3" style="margin-bottom: 60px !important">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <router-link to="attendance">
                    <button class="btn btn-secondary btn-sm float-right mb-3"><i class="fas fa-arrow-alt-circle-left"></i> back</button>
                </router-link>
                <table class="table table-bordered bg-white mt-3">
                    <thead>
                        <tr class="bg-secondary text-center text-white">
                            <th>TARIKH</th>
                            <th>HARI</th>
                            <th>KEHADIRAN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in report" :key="index" class="text-center">
                            <td>{{item.teaching_date | thaiDate}} {{item.start_time | rumiTime}}</td>
                            <td>{{item.teaching_day | dayOfWeek}}</td>
                            <td>
                                <span v-if="item.status == null" class="text-warning">tidak ada catatan</span>
                                <span v-else-if="item.status == 1" class="text-success">hadir</span>
                                <!-- <span v-else-if="item.status == 2" class="text-secondary">lewat</span> -->
                                <span v-else-if="item.status == 3" class="text-danger">ghaib</span>
                                <!-- <span v-else-if="item.status == 4" class="text-info">cuti</span> -->
                                <span v-else class="text-warning">uzur</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import http from '@/services/BackendService'
    export default {
        mounted(){
            this.retreiveDetail()
        },
        data(){
            return{
                s_id: localStorage.getItem("attendance_detail_s_id"),
                report: []
            }
        },
        methods:{
            retreiveDetail(){
                this.$Progress.start()
                let s_id = this.s_id
                http.get(`learning/retriveAttendanceDetail/${s_id}`)
                    .then(response => {
                        // console.log(response.data)
                        this.report = response.data.report
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch("authentication/cencelAuthen")
                        }
                        this.$Progress.fail()
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>